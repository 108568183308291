import arriereBackoffice, {
  CaseRecordType,
  ReviewInfo,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { Alert } from '@avantstay/backoffice-core'

import {
  FullWorkOrder,
  UpdateWorkOrders,
  WorkOrderItemCountQueryResult,
  WorkOrderListResultsPage,
  WorkOrdersListQueryVariables,
} from '@new/domains/operations/screens/WorkOrders/WorkOrders.types'

import { EMPTY_UUID } from '@arriere/modules/cases'

export const getWorkOrdersList = async ({
  term,
  sortBy,
  sortOrder,
  pagination,
  regionIds,
  statuses,
  dueDatePeriod,
  types,
  homeIds,
  tasks,
  caseOwnerIds,
  caseVendorIds,
}: WorkOrdersListQueryVariables): Promise<WorkOrderListResultsPage> => {
  return arriereBackoffice.queries
    .workOrders({
      search: {
        __args: {
          regionIds,
          term,
          sortBy,
          sortOrder,
          pagination,
          statuses,
          dueDatePeriod,
          types,
          homeIds,
          taskTypes: tasks,
          caseOwnerIds,
          caseVendorIds: caseVendorIds?.filter(id => id !== EMPTY_UUID),
          hasCaseVendor: caseVendorIds?.includes(EMPTY_UUID) ? false : undefined,
        },
        values: {
          total: true,
          pages: true,
          results: {
            id: true,
            workOrderType: true,
            sfdcCaseNumber: true,
            hasItemsToReview: true,
            home: {
              id: true,
              name: true,
              thumbnail: {
                url: true,
              },
              region: {
                id: true,
                name: true,
              },
            },
            sfdcStatusApiName: true,
            stats: {
              dueDate: true,
              finalWorkTime: true,
              initialWorkTime: true,
              lastUpdate: true,
              photosMeanPercentage: true,
              workMeanPercentage: true,
            },
            lastReviewer: {
              name: true,
            },
            taskType: true,
            reviewStatus: true,
            updatedAt: true,
            status: true,
            caseVendorsInfo: {
              caseVendorId: true,
              caseVendorName: true,
              caseOwnerVendorId: true,
              caseOwnerVendorName: true,
            },
            workers: {
              vendorId: true,
              fullName: true,
              phone: true,
            },
            vendor: {
              id: true,
              name: true,
            },
          },
        },
        count: {
          pending: true,
          reviewed: true,
          scheduled: true,
          countByType: {
            housekeeping: true,
            inspections: true,
          },
        },
      },
    })
    .then(results => results.search)
}

export const getWorkOrderApprovedCount = async (
  workOrderId: string,
): Promise<WorkOrderItemCountQueryResult> => {
  return arriereBackoffice.queries.workOrders({
    countItems: {
      __args: {
        workOrderId,
      },
      approved: true,
      completedWithBlock: true,
    },
  })
}

export const getWorkOrderById = async (id: string): Promise<FullWorkOrder> => {
  const workOrder = await arriereBackoffice.queries.workOrders({
    find: {
      __args: {
        workOrderId: id,
      },
      id: true,
      description: true,
      managedUnitId: true,
      sfdcCaseNumber: true,
      workOrderType: true,
      forcedApproveAvailable: true,
      reason: true,
      reportedIssues: {
        id: true,
        caseType: true,
        category: true,
        subcategory: true,
        createdAt: true,
        locationInHome: true,
        subject: true,
        reporterName: true,
        description: true,
        sfdcCaseNumber: true,
        reportedFromItemInfo: {
          itemId: true,
          itemTitle: true,
          sectionId: true,
          sectionTitle: true,
        },
        issuePhotos: {
          url: true,
          id: true,
        },
        sfdcCaseUrl: true,
      },
      sections: {
        id: true,
        templateSectionId: true,
        workOrderId: true,
        title: true,
        items: {
          id: true,
          description: true,
          forPets: true,
          instructions: true,
          notes: true,
          photoUploadRequired: true,
          referenceImageId: true,
          expectedNegativeAnswer: true,
          answers: true,
          answerType: true,
          answerOptions: {
            answerId: true,
            label: true,
            expected: true,
          },
          review: {
            reviewer: {
              name: true,
            },
            dateTime: true,
            reason: true,
          },
          referencePhoto: {
            url: true,
            id: true,
          },
          templateItemId: true,
          imageId: true,
          reviewStatus: true,
          workStatus: true,
          kind: true,
          approvedAt: true,
          itemPhoto: {
            url: true,
            id: true,
          },
          itemPhotos: {
            id: true,
            url: true,
            createdAt: true,
          },
        },
        reviewStatus: true,
        createdAt: true,
        updatedAt: true,
        stats: {
          workPercentage: true,
          readyForReviewPercentage: true,
        },
      },
      status: true,
      taskType: true,
      reviewStatus: true,
      inspectorNotes: true,
      stats: {
        dueDate: true,
        workMeanPercentage: true,
        photosMeanPercentage: true,
        initialWorkTime: true,
        lastUpdate: true,
        finalWorkTime: true,
      },
      createdAt: true,
      updatedAt: true,
      lastReviewer: {
        name: true,
      },
      home: {
        id: true,
        name: true,
        thumbnail: {
          url: true,
        },
        region: {
          name: true,
        },
      },
      workers: {
        vendorId: true,
        fullName: true,
        phone: true,
      },
      vendor: {
        id: true,
        name: true,
      },
      tags: true,
    },
  })

  return workOrder.find
}

export const forceApproveWorkOrder = async (workOrderId: string): Promise<ReviewInfo> =>
  arriereBackoffice.mutations
    .workOrders({
      forceApprove: {
        __args: {
          workOrderId,
        },
        reviewer: {
          name: true,
        },
        dateTime: true,
      },
    })
    .then(({ forceApprove }) => forceApprove)

export const acceptWorkOrderItem = async (itemId: string): Promise<ReviewInfo> =>
  arriereBackoffice.mutations
    .checklistItems({
      accept: {
        __args: {
          itemId,
        },
        reviewer: {
          name: true,
        },
        dateTime: true,
        reason: true,
      },
    })
    .then(({ accept }) => accept)

export const rejectWorkOrderItem = async (itemId: string, reason?: string): Promise<ReviewInfo> =>
  arriereBackoffice.mutations
    .checklistItems({
      reject: {
        __args: { itemId, reason },
        reviewer: {
          name: true,
        },
        dateTime: true,
      },
    })
    .then(({ reject }) => reject)

export const makePictureAsReference = async ({
  templateItemId,
  templateSectionId,
  assetId,
  homeId,
  itemId,
}: {
  itemId: string
  templateItemId: string
  templateSectionId: string
  assetId: string
  homeId: UUID
}): Promise<string> => {
  // this updates Work Orders Templates Management BFF DB
  try {
    arriereBackoffice.mutations.setHomeItemReferencePhoto({
      __args: {
        itemId: templateItemId,
        sectionId: templateSectionId,
        homeId,
        assetId,
      },
      referencePhoto: {
        url: true,
      },
    })
  } catch (e) {
    console.error(e)
  }

  // below is to update existing templates on scala BE
  return arriereBackoffice.mutations
    .checklistItems({
      makePictureAsReference: {
        __args: {
          itemId,
          assetId,
        },
        url: true,
      },
    })
    .then(result => result.makePictureAsReference.url)
}

export const searchCaseOwners = async (search: string) => {
  return arriereBackoffice.queries
    .vendors({
      __alias: 'searchCaseOwners',
      searchCaseOwners: {
        __args: {
          term: search,
        },
        id: true,
        name: true,
      },
    })
    .then(results => {
      return results.searchCaseOwners
    })
    .catch(error => {
      Alert.Error((error as Error).message)
      return undefined
    })
}

export const searchCaseVendors = async (search: string) => {
  return arriereBackoffice.queries
    .vendors({
      __alias: 'searchVendorsWithoutCaseOwners',
      searchVendorsWithoutCaseOwners: {
        __args: {
          term: search,
        },
        id: true,
        name: true,
      },
    })
    .then(results => {
      return results.searchVendorsWithoutCaseOwners
    })
    .catch(error => {
      Alert.Error((error as Error).message)
      return undefined
    })
}

export const updateWorkOrder = async (workOrder: UpdateWorkOrders) => {
  return arriereBackoffice.mutations
    .workOrders({
      __alias: 'updateWorkOrderBasic',
      updateWorkOrderBasic: {
        __args: { workOrderUpdate: workOrder },
      },
    })
    .then(results => {
      Alert.Success(`Work order successfully updated.`)
      return results.updateWorkOrderBasic
    })
    .catch(error => {
      Alert.Error((error as Error).message)
      return undefined
    })
}

export const getSfdcStatus = async (recordType: CaseRecordType) => {
  return arriereBackoffice.queries
    .cases({
      __alias: 'getSfdcStatus',
      getCaseInputs: {
        __args: {
          recordType,
        },
        statuses: {
          label: true,
          apiName: true,
        },
      },
    })
    .then(results => {
      return results.getCaseInputs
    })
    .catch(error => {
      Alert.Error((error as Error).message)
      return undefined
    })
}
