// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CheckPicker as BaseCheckPicker } from 'rsuite'
import { StyledComponent } from 'styled-components'

import { IconChevronDown, IconChevronUp } from '@avantstay/backoffice-vectors'

import { mainColors } from '../../styles'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { FlexContainer } from '../FlexContainer'
import { Label } from '../Label'
import { Loading } from '../Loading'
import { Tooltip } from '../Tooltip'
import * as S from './CheckPicker.styles'
import {
  CheckPickerItemType,
  CheckPickerProps,
  CustomMenuAcc,
  ICheckPickerSizeProps,
} from './CheckPicker.types'

// Hacky solution as rsuite doesn't expose the SearchBar component
const onHackyClearSearch = (el: HTMLInputElement) => {
  const event = new Event('input', { bubbles: true })
  const trigger = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set

  trigger?.call(el, '')
  el.dispatchEvent(event)
}

const MULTIPLY_FACTOR_FONT_SIZE = 6

function createCheckPickerComponent<T>(
  Component: StyledComponent<any, any, CheckPickerProps>,
  size?: ICheckPickerSizeProps,
) {
  return ({
    actions = true,
    className,
    customAllText,
    customAllValue,
    defaultValue,
    disabled = false,
    groupBy,
    hideAllOption,
    isLoadingOptions,
    isWithinScrollableElement = false,
    maxTagWidth,
    tagLimit,
    onChange,
    onClean,
    onClose,
    onOpen,
    onSearch,
    onSelect,
    options,
    placeholder = 'Select options',
    placement,
    preventOverflow = false,
    searchable = false,
    searchBy,
    subtitle,
    value,
    title,
    shouldLimitTagVisibility,
    ...props
  }: CheckPickerProps & T) => {
    const containerRef = useRef<any>(null)
    const checkPickerRef = useRef<any>(null)

    const [isOpen, setIsOpen] = useState(false)
    const [componentWidth, setComponentWidth] = useState(0)
    const hasSelection: boolean = value && value.length > 0

    const mappedOptions = useMemo(() => {
      const selectedOptions = options?.filter(option =>
        value?.find(selectedOption => selectedOption === option.value),
      )
      const unselectedOptions = options?.filter(
        option => !value?.find(selectedOption => selectedOption === option.value),
      )

      return {
        selectedOptions,
        unselectedOptions,
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, options])

    useEffect(() => {
      // This will trigger a re-render to update the width and display the default items label
      setComponentWidth(checkPickerRef.current?.target?.clientWidth)
    }, [checkPickerRef.current?.target?.clientWidth])

    const CustomDropdownIndicator = (): React.ReactNode => {
      return (
        <S.DropdownIndicator disabled={disabled}>
          {isOpen ? <IconChevronUp /> : <IconChevronDown />}
        </S.DropdownIndicator>
      )
    }

    const customTitle = () => (title ? <S.CustomTitle>{title}</S.CustomTitle> : null)

    const CustomGroupMenu = (groupMenu: React.ReactNode) => {
      const groupMenuIcons = document.getElementsByClassName(
        'rs-picker-menu-group-caret rs-icon',
      ) as HTMLCollection

      Array.from(groupMenuIcons).forEach((currentSvg: Element) => {
        const element = currentSvg as SVGElement
        if (element?.style) element.style.color = mainColors.neutralExtraDark
      })

      return <S.GroupMenu>{groupMenu}</S.GroupMenu>
    }

    const CustomMenu = (menu: React.ReactNode) => {
      const searchBarInput: HTMLInputElement = document.getElementsByClassName(
        'rs-picker-search-bar-input',
      )[0] as HTMLInputElement

      if (isOpen) searchBarInput?.focus()

      return (
        <S.CustomMenu>
          {isLoadingOptions ? (
            <S.LoadingContainer>
              <Loading size="4px" />
            </S.LoadingContainer>
          ) : (
            <>
              {searchable && <S.SearchBarMagIcon />}
              {searchable && searchBarInput?.value && (
                <S.SearchBarClearIcon
                  onClick={() => {
                    onHackyClearSearch(searchBarInput)
                  }}
                />
              )}
              {actions && (
                <S.MenuWrapper removeBottomBorder={hideAllOption}>
                  <S.ContainerActions>
                    {!hideAllOption && (
                      <Checkbox.Regular
                        id={`checkbox-all-${new Date().toString()}`}
                        value={customAllValue ?? 'all'}
                        title={customAllText ?? 'All'}
                        checked={!hasSelection}
                        onChange={onClean}
                      />
                    )}
                    {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
                    {onClean ? (
                      <Button.Flat disabled={!hasSelection} onClick={onClean}>
                        Clear
                      </Button.Flat>
                    ) : null}
                  </S.ContainerActions>
                  {mappedOptions.selectedOptions.map(selectedOption =>
                    CustomMenuItem(selectedOption.label, selectedOption),
                  )}
                </S.MenuWrapper>
              )}
              {menu}
            </>
          )}
        </S.CustomMenu>
      )
    }

    const CustomMenuItem = (label: React.ReactNode, item: CheckPickerItemType) => {
      return (
        <Checkbox.Regular
          id={item.value}
          key={item.value}
          dot={item.dot}
          subtitle={item.subtitle}
          value={label?.toString()}
          title={label}
          checked={value?.includes(item.value)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onSelect) {
              if (value?.find((v: string) => v === item.value)) {
                const selection = value.filter((i: string) => {
                  return i !== item.value
                })
                onSelect(selection, item, e)
              } else {
                const selection = [...(value || []), item.value]
                onSelect(selection, item, e)
              }
            }
          }}
          hasFullWidth
        />
      )
    }

    const CustomValue = (
      v: any[],
      item: CheckPickerItemType | CheckPickerItemType[],
      selectedElement: React.ReactNode,
    ) => {
      const selectedOptions = options.filter(option =>
        value.find(selectedOption => selectedOption === option.value),
      )

      if (Array.isArray(selectedOptions)) {
        const innerWidth = componentWidth - 12

        const customLabels = selectedOptions.reduce(
          (acc: CustomMenuAcc, currentLabel: CheckPickerItemType) => {
            if (acc.hasReachedRenderLimit) {
              return acc
            }
            // tagLimit prop controls the number of tags that can be displayed before the +n tag
            const hasHardLimit = maxTagWidth == null
            const hasSurpassedHardLimit = hasHardLimit && acc.itemsToRender.length > (tagLimit || 1)

            const labelsLength =
              `${currentLabel?.label || ''} ${acc.itemsToMeasure?.join(' ')}`.length *
              MULTIPLY_FACTOR_FONT_SIZE

            const currentTagsWidth = (acc.itemsToRender.length + 1) * (maxTagWidth ?? 1)
            const hasSurpassedWidthLimit = currentTagsWidth >= innerWidth

            const hasSurpassedVisibilityLimit = shouldLimitTagVisibility
              ? labelsLength >= innerWidth
              : hasSurpassedHardLimit || hasSurpassedWidthLimit

            if (hasSurpassedVisibilityLimit) {
              const itemCount = selectedOptions.length - acc.itemsToRender.length

              const visibleLabels = acc.itemsToRender.map(item => item.props.value)

              const tooltipOptions = options
                .filter(option => value.includes(option.value))
                .filter(optionItem => !visibleLabels.includes(optionItem.label))

              const itemCountElement = (
                <Tooltip
                  key={`+${itemCount}`}
                  content={
                    <FlexContainer gap={3} direction="column" noWrap>
                      {tooltipOptions?.map(item => (
                        <Label.Small key={`${item.value}-tooltip`} value={item.label} />
                      ))}
                    </FlexContainer>
                  }
                  placement="top"
                >
                  <S.CustomLabelWrapper>
                    <Label.Small value={`+${itemCount}`} />
                  </S.CustomLabelWrapper>
                </Tooltip>
              )

              return {
                itemsToRender: [...acc.itemsToRender, itemCountElement],
                itemsToMeasure: [...acc.itemsToMeasure, itemCount],
                hasReachedRenderLimit: true,
              }
            }

            const stringLabel =
              typeof currentLabel.label === 'string'
                ? currentLabel.label
                : currentLabel.customInputLabel

            const customLabel = (
              <Label.Small
                key={`${currentLabel.value}-label`}
                value={stringLabel}
                style={currentLabel?.labelStyle}
              />
            )

            return {
              itemsToRender: [...acc.itemsToRender, customLabel],
              itemsToMeasure: [...(acc.itemsToMeasure || []), stringLabel],
              hasReachedRenderLimit: false,
            }
          },
          {
            itemsToRender: [],
            itemsToMeasure: [],
            hasReachedRenderLimit: false,
          },
        )

        return (
          <FlexContainer key="custom-input" justify="flex-start" noWrap>
            {customTitle()} {customLabels.itemsToRender}
          </FlexContainer>
        )
      }

      return (
        <>
          {customTitle()} {selectedElement}
        </>
      )
    }

    // Todo: review types and fix issues
    return (
      <Component
        {...size}
        ref={containerRef}
        active={isOpen}
        onClick={() => {
          if (!disabled) setIsOpen(true)
        }}
        className={className}
        disabled={disabled}
      >
        <BaseCheckPicker
          block
          groupBy={groupBy ? 'groupBy' : ''}
          ref={checkPickerRef}
          container={() => isWithinScrollableElement && containerRef.current}
          cleanable={false}
          defaultValue={defaultValue}
          disabled={disabled}
          data={mappedOptions.unselectedOptions}
          menuClassName="av-picker-menu"
          placeholder={placeholder}
          placement={placement}
          searchable={searchable}
          value={value}
          onChange={onChange}
          onClose={onClose}
          onClean={onClean}
          onEnter={() => setIsOpen(true)}
          onExit={() => setIsOpen(false)}
          onOpen={onOpen}
          onSearch={onSearch}
          onSelect={onSelect}
          searchBy={searchBy}
          menuStyle={S.MenuStyle}
          preventOverflow={preventOverflow}
          renderMenuGroup={(menuGroup: React.ReactNode) => CustomGroupMenu(menuGroup)}
          renderMenu={(menu: React.ReactNode) => CustomMenu(menu)}
          renderMenuItem={(label: React.ReactNode, item: CheckPickerItemType) =>
            CustomMenuItem(label, item)
          }
          renderValue={(
            v: unknown[],
            item: CheckPickerItemType | CheckPickerItemType[],
            selectedElement: React.ReactNode,
          ) => CustomValue(v, item, selectedElement)}
          {...props}
        />
        {!hasSelection && CustomDropdownIndicator()}
      </Component>
    )
  }
}

const CheckPicker = (): React.ReactNode => createCheckPickerComponent(S.Container)

CheckPicker.Small = createCheckPickerComponent(S.Container, { small: true })
CheckPicker.Default = createCheckPickerComponent(S.Container)

export { CheckPicker }
