import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'
import arriereLighthouse from '@avantstay/arriere-clients/dist/arriereLighthouse'
import arrierePublic from '@avantstay/arriere-clients/dist/arrierePublic'
import { Alert } from '@avantstay/backoffice-core'

import * as auth from './modules/auth'
import * as bookingAdjustment from './modules/bookingAdjustment'
import * as bookingPrices from './modules/bookingPrices'
import * as bookingReview from './modules/bookingReview'
import bookings from './modules/bookings'
import bookingsCreate from './modules/bookingsCreate'
import bookingServices from './modules/bookingServices'
import { bookingsList } from './modules/bookingsList'
import books from './modules/books'
import calendarBlocks from './modules/calendarBlocks'
import cases from './modules/cases'
import coupons from './modules/coupons'
import * as dashboard from './modules/dashboard'
import finance from './modules/finance'
import global from './modules/global'
import * as guestDamagesReport from './modules/guestDamagesReport'
import guests from './modules/guests'
import homeServices from './modules/homeServices'
import * as omniSearch from './modules/omniSearch'
import owners from './modules/owners'
import payments from './modules/payments'
import properties from './modules/properties'
import propertiesList from './modules/propertiesList'
import propertyCreation from './modules/propertyCreation'
import * as quotes from './modules/quotes'
import ratings from './modules/ratings'
import * as recurringBookingBased from './modules/recurringBookingBased'
import * as recurringCases from './modules/recurringCases'
import * as recurringExpenses from './modules/recurringExpenses'
import regions from './modules/regions'
import reports from './modules/reports'
import * as scheduledSms from './modules/scheduledSms'
import settings from './modules/settings'
import staffAndZones from './modules/staffAndZones'
import termsAndConditions from './modules/termsAndConditions'
import usersAndPermissions from './modules/usersAndPermissions'
import * as vendors from './modules/vendors'
import * as workOrders from './modules/workOrders'
import * as workOrderTemplates from './modules/workOrderTemplates'

arriereBackoffice.setHeaders({
  get Authorization() {
    return `Bearer ${localStorage.getItem('id_token')}`
  },
  get 'x-teammate'() {
    return `${localStorage.getItem('runAs') || localStorage.getItem('teammate')}`
  },
})

arriereBackoffice.setHeaders({
  get Authorization() {
    return `Bearer ${localStorage.getItem('id_token')}`
  },
  get 'x-teammate'() {
    return `${localStorage.getItem('teammate')}`
  },
})

arrierePublic.setHeaders({
  get Authorization() {
    return `Bearer ${localStorage.getItem('id_token')}`
  },
})

arriereLighthouse.setHeaders({
  get Authorization() {
    return `Bearer ${localStorage.getItem('id_token')}`
  },
})

// eslint-disable-next-line consistent-return
arriereBackoffice.addResponseListener(({ response }) => {
  if (response?.status === 401) {
    return Alert.Error(
      'You don’t have permission to perform this action. Please, contact your team administrator.',
    )
  }
})

// eslint-disable-next-line consistent-return
arrierePublic.addResponseListener(({ response }) => {
  if (response?.status === 401) {
    return Alert.Error(
      'You don’t have permission to perform this action. Please, contact your team administrator.',
    )
  }
})

export const arriere = {
  auth,
  bookingAdjustment,
  bookingPrices,
  bookings,
  bookingsCreate,
  bookingServices,
  bookingsList,
  bookingReview,
  books,
  coupons,
  dashboard,
  finance,
  global,
  guests,
  homeServices,
  omniSearch,
  payments,
  properties,
  propertyCreation,
  propertiesList,
  quotes,
  regions,
  ratings,
  reports,
  scheduledSms,
  settings,
  termsAndConditions,
  usersAndPermissions,
  workOrders,
  owners,
  workOrderTemplates,
  calendarBlocks,
  staffAndZones,
  cases,
  vendors,
  recurringCases,
  recurringExpenses,
  recurringBookingBased,
  guestDamagesReport,
}
