import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { RateVrboGuestProps, RequestVrboGuestReviewProps } from '@arriere/types/BookingReview.types'

export const getGuestRatingAndReviewInfo = async (bookingId: string) => {
  return arriereBackoffice.queries.booking({
    __args: {
      bookingId,
    },
    reviewStatus: {
      status: true,
      submittedAt: true,
    },
    guestRate: {
      source: true,
      categories: {
        category: true,
        rating: true,
        comment: true,
      },
      recommendedGuest: true,
      createdAt: true,
      createdBy: {
        name: true,
      },
    },
    messagesRequestGuestReview: {
      message: true,
    },
  })
}

export const rateVrboGuest = async ({ bookingId, ratings, recommendGuest }: RateVrboGuestProps) => {
  return arriereBackoffice.mutations.vrboDistribution.raw({
    __alias: 'rateVrboGuest',
    reviews: {
      rateGuest: {
        __args: {
          bookingId,
          ratings,
          recommendGuest,
        },
      },
    },
  })
}

export const requestVrboGuestReview = async ({
  bookingId,
  message,
}: RequestVrboGuestReviewProps) => {
  return arriereBackoffice.mutations.booking.raw({
    __alias: 'requestVrboGuestReview',
    requestGuestReview: {
      __args: {
        bookingId,
        message,
      },
    },
  })
}
