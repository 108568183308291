import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { MarkdownPreviewProps } from './_types'
import { PreviewContainer } from './MarkdownPreview.styles'

export const MarkdownPreview = ({ source, maxLength }: MarkdownPreviewProps) => {
  const value = maxLength && source.length > maxLength ? `${source.slice(0, maxLength)}...` : source
  return (
    <PreviewContainer data-testid="preview">
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{value}</ReactMarkdown>
    </PreviewContainer>
  )
}
