import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconStatusWarning(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.701 3.75C11.2783 2.75 12.7217 2.75 13.299 3.75L22.3923 19.5C22.9697 20.5 22.248 21.75 21.0933 21.75H2.90673C1.75203 21.75 1.03034 20.5 1.6077 19.5L10.701 3.75Z"
        fill="#FFF8EE"
        stroke="#FFD89E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7315 9.8C12.7315 9.35817 12.3998 9 11.9907 9C11.5816 9 11.25 9.35817 11.25 9.8V13.8C11.25 14.2418 11.5816 14.6 11.9907 14.6C12.3998 14.6 12.7315 14.2418 12.7315 13.8V9.8ZM12.7315 17.8C12.7315 18.2418 12.3998 18.6 11.9907 18.6C11.5816 18.6 11.25 18.2418 11.25 17.8C11.25 17.3582 11.5816 17 11.9907 17C12.3998 17 12.7315 17.3582 12.7315 17.8Z"
        fill="#FF9E0D"
      />
    </svg>
  )
}

export const IconStatusWarning = forwardRef(_IconStatusWarning)
