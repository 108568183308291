import arriereBackoffice, {
  CustomFieldInput,
  NoiseLevelExternalSystem,
  OnlineTravelAgency,
  PaginationInput,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { DistributionIssueResolutionInput } from '@arriere/types/Settings.types'

export default {
  async getNoiseLevelDevices(
    externalIds?: string[],
    externalSystems?: NoiseLevelExternalSystem[],
    pagination?: PaginationInput,
  ) {
    return arriereBackoffice.queries.devices({
      noiseLevel: {
        __args: {
          externalIds,
          externalSystems,
          pagination,
        },
        total: true,
        pages: true,
        paginationParams: {
          page: true,
          size: true,
        },
        results: {
          id: true,
          homeId: true,
          name: true,
          additionalData: true,
          home: {
            name: true,
            region: {
              name: true,
            },
          },
        },
      },
    })
  },

  async getAirbnbAmenities(isAmenitiesActive: boolean) {
    return arriereBackoffice.queries.airbnbDistribution({
      amenities: {
        listMappings: {
          __args: { activeOnly: isAmenitiesActive },
          id: true,
          name: true,
          amenities: {
            id: true,
            name: true,
            active: true,
            amenityKinds: true,
            scope: true,
          },
        },
      },
    })
  },

  async getAirbnbTaxesAndFees() {
    return arriereBackoffice.queries
      .airbnbDistribution({
        taxFees: {
          taxFeeTypeMappings: {
            enabled: true,
            feeMapping: {
              feeType: true,
              feeUnitType: true,
            },
            id: true,
            name: true,
            taxMapping: {
              taxAmountType: true,
              taxType: true,
            },
            unit: true,
            valueType: true,
            revenueTargets: true,
          },
        },
      })
      .then(results => results.taxFees.taxFeeTypeMappings)
  },

  assignNoiseLevelDevice(deviceId: UUID, homeId: UUID) {
    return arriereBackoffice.mutations.devices({
      assignNoiseLevelDevice: { __args: { deviceId, homeId } },
    })
  },
  unAssignNoiseLevelDevice(deviceId: UUID) {
    return arriereBackoffice.mutations.devices({
      unassignNoiseLevelDevice: { __args: { deviceId } },
    })
  },
  updateCustomFields(customFields: CustomFieldInput[]) {
    return arriereBackoffice.mutations.updateCustomFields({
      __args: { customFields },
      id: true,
    })
  },
}

// Queries

export const getBlacklistedEmails = async (pagination?: { page: number; size: number }) => {
  const { data, errors } = await arriereBackoffice.queries.blacklistedEmails.raw({
    __alias: 'getBlacklistedEmails',
    __args: { pagination },
    total: true,
    pages: true,
    results: {
      email: true,
    },
  })
  if (errors?.length) {
    throw new Error(errors[0]?.message)
  }
  return data
}

export const getListingIssueSolutionsList = async (ota: OnlineTravelAgency) => {
  return arriereBackoffice.queries.distribution
    .raw({
      __alias: 'getListingIssueSolutionsList',
      distributionIssueResolutions: {
        __args: { ota },
        code: true,
        kind: true,
        issueType: true,
        priority: true,
        categories: true,
        agency: true,
        description: true,
        instructions: true,
        errorMessage: true,
        isPermitIssue: true,
        isPropertySpecific: true,
        isResolvable: true,
        unresolvableReason: true,
        createdAt: true,
        updatedAt: true,
        createdBy: {
          kind: true,
          name: true,
        },
        updatedBy: {
          kind: true,
          name: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors.length - 1]?.message)
      }

      return data.distributionIssueResolutions
    })
}

// Mutations

export const addEmailToBlacklist = (email: string) => {
  return arriereBackoffice.mutations.addToBlacklist({
    __alias: 'addEmailToBlacklist',
    __args: { email },
  })
}

export const deleteEmailFromBlacklist = (email: string) => {
  return arriereBackoffice.mutations.deleteFromBlacklist({
    __alias: 'deleteEmailFromBlacklist',
    __args: { email },
  })
}

export const updateDistributionIssueResolutions = (
  resolutions: DistributionIssueResolutionInput[],
) => {
  return arriereBackoffice.mutations.distribution({
    __alias: 'updateDistributionIssueResolutions',
    updateDistributionIssueResolutions: {
      __args: { resolutions },
    },
  })
}
