import gql from 'graphql-tag'

export const exportBookingsListCSV = (exportBody: string) => gql`
  query getBookingsListCSV(
    $filter: String
    $from: LocalDate
    $to: LocalDate
    $regionId: UUID
    $regionIds: [UUID!]
    $leadSources: [LeadSource!]
    $longTerm: Boolean
    $pagination: PaginationInput
    $pendingServiceRequests: Boolean
    $repeated: Boolean
    $riskLevels: [RiskLevel!]
    $securityDepositOnHold: Boolean
    $sortBy: BookingSorting
    $sortOrder: SortOrder
    $status: [BookingStatus!]
    $tier: BookingTier
    $acquisition: Boolean
    $tags: [BookingTagIdInput!]
    $reservationTypes: [UUID!]
    $dates: BookingDatesFilter
    $price: BookingPriceFilterInput
    $earlyCheckInStatus: [ServiceRequestStatus!]
    $periodReference: BookingPeriodReference
    $checkInIssues: [BookingCheckInIssue!]
    $homeId: UUID
    $homeIds: [UUID!]
    $checkInInstructionsStatus: [CheckInInstructionsStatus!]
    $leadSource: LeadSource
    $updatedAfter: ZonedDateTime
  ) {
    _: bookings(
      regionId: $regionId
      regionIds: $regionIds
      leadSource: $leadSource
      leadSources: $leadSources
      longTerm: $longTerm
      pagination: $pagination
      pendingServiceRequests: $pendingServiceRequests
      repeated: $repeated
      filter: $filter
      riskLevels: $riskLevels
      securityDepositOnHold: $securityDepositOnHold
      sortBy: $sortBy
      sortOrder: $sortOrder
      status: $status
      tier: $tier
      tags: $tags
      acquisition: $acquisition
      reservationTypes: $reservationTypes
      dates: $dates
      price: $price
      earlyCheckInStatus: $earlyCheckInStatus
      periodReference: $periodReference
      checkInIssues: $checkInIssues
      homeId: $homeId
      homeIds: $homeIds
      checkInInstructionsStatus: $checkInInstructionsStatus
      from: $from
      to: $to
      updatedAfter: $updatedAfter
    ) {
      total
      pages
      results ${exportBody}
    }
  }
`
