import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { CreateRecurring } from '@new/domains/operations/screens/RecurringServices/RecurringServices.types'

interface SearchSettingsFilterProps {
  pagination: { size: number; page: number }
}

export const searchRecurring = async (args: SearchSettingsFilterProps) => {
  const response = await arriereBackoffice.queries.recurringCaseSettings({
    __alias: 'searchSettings',
    searchSettings: {
      __args: args,
      pages: true,
      total: true,
      results: {
        id: true,
        subject: true,
        description: true,
        imageIds: true,
        vendorId: true,
        serviceName: true,
        priority: true,
        recordType: true,
        typeSfdcApiName: true,
        statusSfdcApiName: true,
        categorySfdcApiName: true,
        subcategorySfdcApiName: true,
        comment: true,
        homeIds: true,
        regionIds: true,
        frequency: true,
        frequencyInterval: true,
        weekDays: true,
        startDate: true,
        endDate: true,
        usePropertyOffboardingDate: true,
        isDeleted: true,
      },
    },
  })

  return response.searchSettings
}

export const getRecurringVendors = async () => {
  const response = await arriereBackoffice.queries.recurringCaseSettings({
    __alias: 'getVendorsForFiltering',
    getVendorsForFiltering: {
      id: true,
      name: true,
    },
  })
  return response.getVendorsForFiltering
}

export const getMappings = async () => {
  const response = await arriereBackoffice.queries.recurringCaseSettings({
    __alias: 'getMappings',
    getMappings: {
      serviceToCaseFields: {
        serviceName: true,
        recordType: true,
        typesSfdcApiNames: true,
        categorySfdcApiName: true,
        subcategorySfdcApiName: true,
      },
    },
  })
  return response.getMappings
}

export const searchHomes = async (name: string, vendorSfdcId: string) => {
  const response = await arriereBackoffice.queries.vendors({
    __alias: 'searchHomes',
    searchHomes: {
      __args: {
        homeNameSearchTerm: name,
        vendorSfdcId: vendorSfdcId,
      },
      vendorHomes: {
        id: true,
        name: true,
        region: {
          name: true,
        },
      },
      otherHomes: {
        id: true,
        name: true,
        region: {
          name: true,
        },
      },
    },
  })
  return response.searchHomes
}

export const getVendorHomes = async (sfdcVendorId: string) => {
  const response = await arriereBackoffice.queries.vendors({
    __alias: 'sfdcVendorHomes',
    sfdcVendorHomes: {
      __args: {
        sfdcVendorId,
      },
      id: true,
      name: true,
      region: {
        name: true,
      },
    },
  })

  return response.sfdcVendorHomes
}

export const createRecurring = async (data: CreateRecurring) => {
  const response = await arriereBackoffice.mutations.recurringCaseSettings({
    __alias: 'CreateRecurring',
    createSetting: {
      __args: { body: data },
    },
  })

  return response.createSetting
}

export const updateRecurring = async (id: string, data: CreateRecurring) => {
  const response = await arriereBackoffice.mutations.recurringCaseSettings({
    __alias: 'UpdateRecurring',
    updateSetting: {
      __args: { id, body: data },
    },
  })

  return response.updateSetting
}

export const deleteRecurring = async (id: string) => {
  const response = await arriereBackoffice.mutations.recurringCaseSettings({
    __alias: 'DeleteRecurring',
    deleteSetting: {
      __args: { id },
    },
  })

  return response.deleteSetting
}

export const getRecurringDetails = async (recurringCaseSettingId: string) => {
  const response = await arriereBackoffice.queries.recurringCaseSettings({
    __alias: 'getRecurringDetails',
    findSetting: {
      __args: {
        recurringCaseSettingId,
      },
      id: true,
      subject: true,
      description: true,
      imageIds: true,
      vendorId: true,
      serviceName: true,
      priority: true,
      recordType: true,
      typeSfdcApiName: true,
      statusSfdcApiName: true,
      categorySfdcApiName: true,
      subcategorySfdcApiName: true,
      comment: true,
      homeIds: true,
      regionIds: true,
      frequency: true,
      frequencyInterval: true,
      weekDays: true,
      startDate: true,
      endDate: true,
      usePropertyOffboardingDate: true,
      isDeleted: true,
      homes: {
        id: true,
        name: true,
        region: {
          id: true,
          name: true,
        },
      },
      vendor: {
        id: true,
        name: true,
      },
    },
  })

  return response.findSetting
}
