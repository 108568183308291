import styled from 'styled-components'

import { mainColors } from '@avantstay/backoffice-core'

export const StarsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const IconStarWrapper = styled.div<{
  $isActive?: boolean
  $isHidden?: boolean
  $isInteractive?: boolean
}>`
  display: flex;
  align-items: center;
  opacity: 0.3;

  ${({ $isHidden }) =>
    $isHidden &&
    `
    color: transparent;
    fill: transparent;
  `};

  ${({ $isActive }) =>
    $isActive &&
    `
    opacity: 1;
    fill: ${mainColors.black};
    color: ${mainColors.black};
  `};

  ${({ $isInteractive }) =>
    $isInteractive &&
    `
    cursor: pointer;

    &:hover,
    &:has(~ div:hover) {
      opacity: 1;
      fill: ${mainColors.black};
      color: ${mainColors.black};
    }

    &:hover {
      transform: scale(1.2);
      transition: all 200ms;
      color: ${mainColors.black};
    }

  `};
`
