import styled from 'styled-components'

import { mainColors } from '../../styles'
import { CircleProps, LoadingProps } from './Loading.types'

export const LoadingStyled = styled.div<LoadingProps>`
  display: flex;
  width: 100%;
  height: ${props => (props.absolute ? '100%' : '70%')};
  min-height: ${props => props.minHeight || '20px'};
  justify-content: ${props => props.alignment};
  align-items: center;
  margin: 0 auto;
  position: ${props => (props.absolute ? 'absolute' : 'relative')};
  top: 0;
`

export const Circle = styled.span<CircleProps>`
  animation: jumpingDots 700ms infinite linear;
  display: inline-block;
  min-width: ${props => props.size};
  min-height: ${props => props.size};
  border-radius: 50%;
  background-color: ${props => (props.color ? props.color : mainColors.neutralDark)};
  margin-left: ${props => props.size};

  @keyframes jumpingDots {
    20% {
      transform: translateY(+50%);
    }
    40% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(-50%);
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`

export const LoadingSectionContainer = styled.div`
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
