import styled from 'styled-components'

import { IconChevronDown } from '@avantstay/backoffice-vectors'

import { mainColors, semanticColors } from '../../styles/colors'
import { _S_Normal } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'
import { InfoMessageType } from './InfoMessage.types'

const chooseMessageBackground = (type: InfoMessageType) => {
  const backgrounds = {
    warning: rgba(mainColors.neutralDark, 0.21),
    success: rgba(semanticColors.successMedium, 0.1),
    error: semanticColors.failureExtraLight,
    risky: semanticColors.warningLightMedium,
  }
  return backgrounds[type]
}

export const chooseMessageColor = (color: InfoMessageType) => {
  const colors = {
    warning: mainColors.neutralExtremelyDark,
    success: semanticColors.successMedium,
    error: semanticColors.failureMedium,
    risky: semanticColors.warningExtraDark,
  }
  return colors[color]
}

export const InfoMessageContainer = styled.div<{ type: InfoMessageType; margin?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin: ${({ margin }) => margin || 0};
  padding: 10px 14px;
  ${_S_Normal};
  line-height: 1.5;
  color: ${({ type }) => chooseMessageColor(type)};
  background: ${({ type }) => chooseMessageBackground(type)};
  border-radius: 2px;
  gap: 8px;
  white-space: break-spaces;

  svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    align-self: center;
  }
`

const CONTENT_PADDING_WIDTH = 12

export const CollapsibleContainer = styled.div<{ type: InfoMessageType; margin?: string }>`
  color: ${({ type }) => chooseMessageColor(type)};
  background-color: ${({ type }) => chooseMessageBackground(type)};
  margin: ${({ margin }) => margin || 0};
  padding: ${CONTENT_PADDING_WIDTH}px 14px 0;

  ${InfoMessageContainer} {
    background-color: transparent;
    padding: 0 0 ${CONTENT_PADDING_WIDTH}px;
  }
`

export const CollapsibleContent = styled.div<{ type: InfoMessageType }>`
  color: ${({ type }) => chooseMessageColor(type)};
  border-top: 1px dashed ${({ type }) => chooseMessageColor(type)};
  padding: ${CONTENT_PADDING_WIDTH}px 0;
`

export const IconChevron = styled(IconChevronDown)<{ scale: number }>`
  transform: ${({ scale }) => `scaleY(${scale})`};
`
