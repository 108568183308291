import arriereBackoffice, {
  DateRange,
  DateRangeInput,
  DiscountType,
  IDate,
  SortOrder,
  TaxFeeSorting,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  CreateSeasonGroupInput,
  CreateSeasonInput,
  SeasonPerGroupPeriodInput,
  UpdateSeasonGroupInput,
  UpdateSeasonInput,
} from '@new/domains/finance/types/api/seasonTypes'
import {
  CreateTaxFeeVariables,
  DeleteTaxOrFeeByIdResults,
  GetLinkToDownloadCSVResults,
  GetTaxAndFeesVariables,
  TaxHistoryResults,
  UpdatedHomeTaxFeeAgencyOverride,
  UpdateTaxResults,
  UpdateTaxVariables,
} from '@new/domains/finance/types/api/taxesAndFeesFilters.types'
import {
  CreateYieldGroupInput,
  CreateYieldMapInput,
  UpdateYieldGroupInput,
  UpdateYieldMapInput,
} from '@new/domains/finance/types/api/yieldingTypes'

export default {
  // TAXES AND FEES

  getTaxesAndFees({ sortByField, sortOrder, nameTerm }: GetTaxAndFeesVariables) {
    return arriereBackoffice.queries.searchTaxes({
      __alias: 'searchTaxes',
      __args: {
        pagination: { size: 1000, page: 1 },
        sortOrder: sortOrder || SortOrder.ascending,
        sortBy: sortByField || TaxFeeSorting.name,
        nameTerm,
      },
      results: {
        id: true,
        applicationValues: true,
        name: true,
        value: true,
        valueType: true,
        isTax: true,
        publicName: true,
        unit: true,
        enabled: true,
        updatedAt: true,
        createdAt: true,
        embedded: true,
        archived: true,
        feeCalculationType: true,
        revenueTargets: true,
        lastUpdateMeta: {
          changedAt: true,
          changedBy: true,
        },
        maxStay: true,
        minStay: true,
        airbnbRemitted: true,
        feeApplicationType: true,
      },
    })
  },
  deleteTaxOrFeeById(id: UUID): Promise<DeleteTaxOrFeeByIdResults> {
    return arriereBackoffice.mutations.taxMutations({
      deleteTax: {
        __args: {
          id,
        },
      },
    })
  },
  updateEnabledTax({
    taxId,
    name,
    publicName,
    isTax,
    unit,
    value,
    maxStayValue,
    minStay,
    feeCalculationType,
    enabled,
    applicationValues,
    applicationKind,
    maxStayKind,
  }: UpdateTaxVariables): Promise<UpdateTaxResults> {
    return arriereBackoffice.mutations.taxMutations({
      updateTaxV2: {
        __args: {
          taxId,
          name,
          publicName,
          isTax,
          unit,
          value,
          maxStayValue,
          minStay,
          feeCalculationType,
          enabled,
          applicationValues,
          applicationKind,
          maxStayKind,
        },
      },
    })
  },
  updateTax({
    taxId,
    name,
    publicName,
    isTax,
    unit,
    value,
    minStay,
    feeCalculationType,
    enabled,
    applicationValues,
    applicationKind,
    maxStayKind,
    maxStayValue,
    revenueTargets,
  }: UpdateTaxVariables): Promise<UpdateTaxResults> {
    return arriereBackoffice.mutations.taxMutations({
      updateTaxV2: {
        __args: {
          taxId,
          name,
          publicName,
          isTax,
          unit,
          value,
          minStay,
          feeCalculationType,
          enabled,
          applicationValues,
          applicationKind,
          maxStayKind,
          maxStayValue,
          revenueTargets,
        },
      },
    })
  },
  createTaxOrFee({
    minStay,
    maxStay,
    name,
    publicName,
    isTax,
    applicationKind,
    applicationValues,
    value,
    valueType,
    enabled,
    unit,
    feeCalculationType,
    revenueTargets,
  }: CreateTaxFeeVariables): Promise<{
    createTax: boolean
  }> {
    return arriereBackoffice.mutations.taxMutations({
      createTax: {
        __args: {
          minStay,
          maxStay,
          name,
          publicName,
          isTax,
          applicationKind,
          applicationValues,
          value,
          valueType,
          enabled,
          unit,
          feeCalculationType,
          revenueTargets,
        },
      },
    })
  },
  getChangeLogHistory(taxId: UUID): Promise<TaxHistoryResults> {
    return arriereBackoffice.queries.taxChangelog({
      __args: {
        taxId,
        pagination: { page: 1, size: 10 },
      },
      results: {
        diffs: {
          kind: true,
          homeChange: {
            action: true,
            homes: true,
          },
          fieldChange: {
            from: true,
            name: true,
            to: true,
          },
          stateChange: {
            state: true,
          },
        },
        initiator: true,
        timestamp: true,
      },
    })
  },
  getLinkToDownloadCSV(): Promise<GetLinkToDownloadCSVResults> {
    return arriereBackoffice.mutations.taxReports({
      homeTaxValues: true,
    })
  },

  updateOTATaxFeeValues(list: UpdatedHomeTaxFeeAgencyOverride[]): Promise<{ updateV2: boolean }> {
    return arriereBackoffice.mutations.homeTaxFeeAgencyOverrideMutations({
      updateV2: {
        __args: { list },
      },
    })
  },

  // SEASONS

  getAllSeasons(asOf?: IDate) {
    return arriereBackoffice.queries.seasonsGrid({
      __args: {
        asOf,
      },
      id: true,
      name: true,
      description: true,
      type: true,
      periods: {
        dateRange: {
          start: true,
          end: true,
        },
        conditions: {
          minStay: true,
          checkInAllowed: true,
          checkOutAllowed: true,
        },
      },
      seasonGroupCount: true,
      lastUpdate: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
      createdAt: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
      isArchived: true,
    })
  },

  getSeasonById(id: UUID, asOf?: IDate) {
    return arriereBackoffice.queries.season({
      __args: {
        id,
        asOf,
      },
      id: true,
      name: true,
      description: true,
      type: true,
      periods: {
        id: true,
        dateRange: {
          start: true,
          end: true,
        },
        minStay: true,
        checkInAllowed: true,
        checkOutAllowed: true,
        createdAt: true,
        lastUpdate: true,
      },
      lastUpdate: true,
      createdAt: true,
      seasonGroups: {
        id: true,
        name: true,
      },
    })
  },

  createSeason({ name, description, type, periods, seasonGroups }: CreateSeasonInput) {
    return arriereBackoffice.mutations.seasonMutations
      .raw({
        create: {
          __args: {
            name,
            description,
            type,
            periods,
            seasonGroups,
          },
          id: true,
        },
      })
      .then(({ data, errors }) => {
        if (errors) throw Error(errors[1]?.message)
        return data?.create
      })
  },

  updateSeason({ id, name, description, type, periods, seasonGroups }: UpdateSeasonInput) {
    return arriereBackoffice.mutations.seasonMutations
      .raw({
        update: {
          __args: {
            id,
            name,
            description,
            type,
            periods,
            seasonGroups,
          },
          id: true,
        },
      })
      .then(({ data, errors }) => {
        if (errors) throw Error(errors[1]?.message)
        return data?.update
      })
  },

  duplicatePeriod(id: string, newDateRange: DateRangeInput) {
    return arriereBackoffice.mutations
      .seasonMutations({
        duplicatePeriod: {
          __args: {
            id,
            newDateRange,
          },
        },
      })
      .then(response => !!response?.duplicatePeriod)
  },

  deleteSeason(id: UUID): Promise<boolean> {
    return arriereBackoffice.mutations
      .seasonMutations({
        delete: {
          __args: { id },
        },
      })
      .then(response => !!response)
  },

  archiveSeason(id: UUID): Promise<boolean> {
    return arriereBackoffice.mutations
      .seasonMutations({
        archive: {
          __args: { id },
        },
      })
      .then(response => !!response)
  },

  // SEASON GROUPS

  getSeasonGroups(asOf?: IDate) {
    return arriereBackoffice.queries.seasonGroupsGrid({
      __args: { asOf },
      id: true,
      name: true,
      description: true,
      lastUpdate: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
      createdAt: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
      isArchived: true,
      propertyCount: true,
      seasonCount: true,
    })
  },

  getSeasonGroupById(id: UUID, asOf?: IDate) {
    return arriereBackoffice.queries.seasonGroup({
      __args: { id, asOf },
      id: true,
      name: true,
      description: true,
      createdAt: true,
      lastUpdate: true,
      isArchived: true,
      seasons: {
        id: true,
      },
      properties: {
        id: true,
        name: true,
        regionName: true,
        regionId: true,
      },
    })
  },

  createSeasonGroup({ name, description, properties }: CreateSeasonGroupInput): Promise<boolean> {
    return arriereBackoffice.mutations
      .seasonGroupMutations({
        create: {
          __args: {
            name,
            description,
            properties,
            // RFOX-TODO; remove seasons
            seasons: [],
          },
          id: true,
        },
      })
      .then(result => !!result)
  },

  updateSeasonGroup({
    id,
    name,
    description,
    properties,
    seasons,
  }: UpdateSeasonGroupInput): Promise<boolean> {
    return arriereBackoffice.mutations
      .seasonGroupMutations({
        update: {
          __args: {
            id,
            name,
            description,
            properties,
            // RFOX-TODO; remove seasons
            seasons,
          },
          id: true,
        },
      })
      .then(result => !!result)
  },

  archiveSeasonGroup(id: UUID): Promise<boolean> {
    return arriereBackoffice.mutations
      .seasonGroupMutations({
        archive: {
          __args: { id },
        },
      })
      .then(response => !!response)
  },

  // SEASONS PER GROUP

  getSeasonsPerGroup(seasonGroupId: UUID, asOf?: IDate) {
    return arriereBackoffice.queries.seasonPerGroupGrid({
      __args: {
        seasonGroupId,
        asOf,
      },
      id: true,
      name: true,
      description: true,
      type: true,
      periods: {
        dateRange: {
          start: true,
          end: true,
        },
        dateRangeOverwritten: true,
        minStayOverwritten: true,
        ciOverwritten: true,
        coOverwritten: true,
        conditions: {
          minStay: true,
          checkInAllowed: true,
          checkOutAllowed: true,
        },
      },
      lastUpdate: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
      createdAt: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
    })
  },

  getSeasonsPerGroupForTapeChart(seasonGroupId: UUID, period?: DateRange, asOf?: IDate) {
    return arriereBackoffice.queries.seasonPerGroupGrid({
      __args: {
        seasonGroupId,
        asOf,
        period,
      },
      id: true,
      name: true,
    })
  },

  getSelectedSeasonPerGroup(id: UUID, asOf?: IDate) {
    return arriereBackoffice.queries.seasonPerGroup({
      __args: { id, asOf },
      id: true,
      name: true,
      description: true,
      type: true,
      periods: {
        id: true,
        dateRange: {
          start: true,
          end: true,
        },
        minStay: true,
        checkInAllowed: true,
        checkOutAllowed: true,
        createdAt: true,
        lastUpdate: true,
      },
      createdAt: true,
      lastUpdate: true,
    })
  },

  updateSeasonPerGroup(seasonId: UUID, periods: [SeasonPerGroupPeriodInput], description?: string) {
    return arriereBackoffice.mutations.seasonPerGroupMutations
      .raw({
        update: {
          __args: {
            id: seasonId,
            description,
            periods,
          },
          id: true,
        },
      })
      .then(({ data, errors }) => {
        if (errors) throw Error(errors[1]?.message)
        return data?.update
      })
  },

  // YIELDING MAPS

  getSelectedYieldingMap(id: string) {
    return arriereBackoffice.queries.yieldingMap({
      __args: {
        id,
      },
      id: true,
      name: true,
      description: true,
      createdAt: true,
      propertyCount: true,
      lastUpdate: true,
      items: {
        leadTime: true,
        occupancy: true,
        value: true,
      },
      properties: {
        id: true,
        name: true,
        regionId: true,
        regionName: true,
      },
    })
  },

  getAllYieldingMaps() {
    return arriereBackoffice.queries.yieldingMapsGrid({
      id: true,
      name: true,
      description: true,
      propertyCount: true,
      isArchived: true,
      lastUpdate: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
      createdAt: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
    })
  },
  createYieldingMap(args: CreateYieldMapInput): Promise<boolean> {
    return arriereBackoffice.mutations
      .yieldingMapMutations({
        create: {
          __args: args,
          id: true,
        },
      })
      .then(response => !!response?.create)
  },
  updateYieldingMap(args: UpdateYieldMapInput): Promise<boolean> {
    return arriereBackoffice.mutations
      .yieldingMapMutations({
        update: {
          __args: args,
        },
      })
      .then(response => !!response?.update)
  },
  deleteYieldingMap(id: string): Promise<boolean> {
    return arriereBackoffice.mutations
      .yieldingMapMutations({
        delete: {
          __args: { id },
        },
      })
      .then(response => !!response?.delete)
  },

  // DISCOUNTS

  getDiscountList() {
    return arriereBackoffice.queries
      .discounts({
        list: {
          homeId: true,
          homeName: true,
          regionId: true,
          regionName: true,
          weeklyDiscount: true,
          monthlyDiscount: true,
        },
      })
      .then(result => result.list)
  },

  updateDiscountHomes(homes: [UUID], value: number, discountType: DiscountType): Promise<boolean> {
    return arriereBackoffice.mutations
      .discounts({
        save: {
          __args: { homes, value, discountType },
        },
      })
      .then(response => !!response)
  },

  // YIELDING GROUPS

  getSelectedYieldingGroup(id: string) {
    return arriereBackoffice.queries.yieldingGroup({
      __args: {
        id,
      },
      id: true,
      name: true,
      description: true,
      createdAt: true,
      lastUpdate: true,
      properties: {
        id: true,
        name: true,
        regionId: true,
        regionName: true,
      },
      propertyCount: true,
    })
  },

  getAllYieldingGroups() {
    return arriereBackoffice.queries.yieldingGroupGrid({
      id: true,
      name: true,
      description: true,
      propertyCount: true,
      isArchived: true,
      lastUpdate: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
      createdAt: {
        time: true,
        author: {
          kind: true,
          name: true,
        },
      },
    })
  },

  createYieldingGroup(args: CreateYieldGroupInput): Promise<boolean> {
    return arriereBackoffice.mutations
      .yieldingGroupMutations({
        create: {
          __args: args,
          id: true,
        },
      })
      .then(response => !!response?.create)
  },

  updateYieldingGroup(args: UpdateYieldGroupInput): Promise<boolean> {
    return arriereBackoffice.mutations
      .yieldingGroupMutations({
        update: {
          __args: args,
        },
      })
      .then(response => !!response?.update)
  },

  deleteYieldingGroup(id: string): Promise<boolean> {
    return arriereBackoffice.mutations
      .yieldingGroupMutations({
        delete: {
          __args: { id },
        },
      })
      .then(response => !!response?.delete)
  },

  getSeasonPrices(homeIds: UUID[], period: DateRange, seasonGroupId: UUID, seasonIds?: UUID[]) {
    return arriereBackoffice.queries
      .seasonPrices({
        list: {
          __args: {
            homeIds,
            period,
            seasonGroups: [seasonGroupId],
            seasonIds,
          },
          type: true,
          period: { start: true, end: true },
          values: { homeId: true, dayOfWeek: true, value: true },
          seasonPeriod: { id: true, name: true },
          season: { id: true, name: true },
        },
      })
      .then(result => result.list)
  },
}
