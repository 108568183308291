import styled from 'styled-components'

import { mainColors } from '../../styles'
import { Button } from '../Button'

export const Root = styled.div`
  display: flex;
  gap: 30px;
`

export const ArrowButton = styled(Button.Flat)<{ blue?: boolean }>`
  display: flex;
  color: ${p => (p.blue ? mainColors.accentMedium : mainColors.neutralExtraDark)};
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    &:not([disabled]) {
      color: ${p => (p.blue ? mainColors.accentDark : mainColors.neutralExtremelyDark)};
    }
  }

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`
