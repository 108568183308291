import { isWithinInterval, startOfToday } from 'date-fns'
import orderBy from 'lodash/orderBy'

import {
  CustomFieldSectionProjection,
  IDate,
  LeadSource,
  ListingLink,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { getNewUuid, parseISODate } from '@avantstay/backoffice-core'

import { ExtendedHomeView } from '@new/domains/properties/_types'
import { listingAndDistributionSectionsIds } from '@new/domains/properties/screens/propertyDistributionChannels/DistributionChannels.utils'
import { homeownerInfoSectionsIds } from '@new/domains/properties/screens/propertyHomeownerInfo/PropertyHomeownerInfo.utils'
import {
  PropertyInfoFields,
  PropertyInfoFormProps,
} from '@new/domains/properties/screens/propertyInfo/PropertyInfo.types'
import { internalConfigurationSectionsIds } from '@new/domains/properties/screens/propertyInternalConfiguration/PropertyInternalConfiguration.utils'

import { notReadOnlySection, withFields } from '@utils/customFields'

interface HomeLease {
  startDate?: IDate
  endDate?: IDate
}

export function getCurrentLeasing(leases: HomeLease[]): HomeLease | undefined {
  const today = startOfToday()
  return leases.find(({ startDate, endDate }) => {
    if (startDate && endDate && startDate < endDate) {
      return isWithinInterval(today, { start: parseISODate(startDate), end: parseISODate(endDate) })
    }
    return undefined
  })
}

export const searchContains = (term: string | undefined, searchList: string[]) =>
  !term || searchList.some(item => item.toLowerCase().includes(term.toLowerCase()))

export const getLinkByLeadSource = (
  homeLinks: ListingLink[],
  leadSource: LeadSource,
): ListingLink | undefined => {
  return homeLinks.find(it => it.leadSource === leadSource)
}

export const ownerStatusCustomFieldId = getNewUuid()

interface GetMapLinkProps {
  latitude: number
  longitude: number
  zoom?: number
}

export const getMapLink = ({ latitude, longitude, zoom = 13 }: GetMapLinkProps) =>
  `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=color:blue|${latitude},${longitude}&zoom=${zoom}&size=445x180&maptype=roadmap&key=${process.env.REACT_APP_TABLEAU_GOOGLE_STATIC_MAP_KEY}`

export const getValidPropertyBasicField = (fieldName: string) =>
  Object.values(PropertyInfoFields).includes(fieldName as PropertyInfoFields)

export const getFieldHistory = (fieldKey: string, data: ExtendedHomeView) => {
  const field = data.verifyItems.find(field => field.item === fieldKey)

  return {
    lastVerifiedAt: field?.verifiedAt,
    lastVerifiedBy: {
      name: field?.author.name,
      kind: field?.author.kind,
    },
  }
}

const getFieldKey = (field: PropertyInfoFields) =>
  Object.entries(PropertyInfoFields).filter(([_, fieldKey]) => fieldKey === field)[0][0]

export const parseHomeFieldToBasicForm = (home: ExtendedHomeView): PropertyInfoFormProps => {
  return {
    name: {
      key: getFieldKey(PropertyInfoFields.name),
      value: home.name,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.name, home),
    },
    title: {
      key: getFieldKey(PropertyInfoFields.title),
      value: home.title,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.title, home),
    },
    shortDescription: {
      key: getFieldKey(PropertyInfoFields.shortDescription),
      value: home.shortDescription,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.shortDescription, home),
    },
    description: {
      key: getFieldKey(PropertyInfoFields.description),
      value: home.description,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.description, home),
    },
    address: {
      key: getFieldKey(PropertyInfoFields.address),
      value: home.address?.address,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.address, home),
    },
    city: {
      key: getFieldKey(PropertyInfoFields.city),
      value: home.address?.city,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.city, home),
    },
    cityId: {
      key: getFieldKey(PropertyInfoFields.cityId),
      value: home.address
        ? {
            value: home.address?.cityId,
            label: home.address?.city,
            subtitle: home.address?.state?.name,
          }
        : undefined,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.cityId, home),
    },
    zipCode: {
      key: getFieldKey(PropertyInfoFields.zipCode),
      value: home.address?.zipCode,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.zipCode, home),
    },
    latitude: {
      key: getFieldKey(PropertyInfoFields.latitude),
      value: home.latitude,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.latitude, home),
    },
    longitude: {
      key: getFieldKey(PropertyInfoFields.longitude),
      value: home.longitude,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.longitude, home),
    },
    shouldOverwriteCoordinates: {
      key: getFieldKey(PropertyInfoFields.shouldOverwriteCoordinates),
      value: false,
      history: getFieldHistory(PropertyInfoFields.shouldOverwriteCoordinates, home),
    },
    checkOutTime: {
      key: getFieldKey(PropertyInfoFields.checkOutTime),
      value: home.checkTime.checkOut,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.checkOutTime, home),
    },
    maxOccupancy: {
      key: getFieldKey(PropertyInfoFields.maxOccupancy),
      value: home.maxOccupancy,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.maxOccupancy, home),
    },
    maxAdults: {
      key: getFieldKey(PropertyInfoFields.maxAdults),
      value: home.maxAdults,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.maxAdults, home),
    },
    homeRegion: {
      key: getFieldKey(PropertyInfoFields.homeRegion),
      value: home.address?.regionId,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.homeRegion, home),
    },
    homeAncestors: {
      key: getFieldKey(PropertyInfoFields.homeAncestors),
      value: home.buyouts?.map(home => home.id),
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.homeAncestors, home),
    },
    bathroomsCount: {
      key: getFieldKey(PropertyInfoFields.bathroomsCount),
      value: home.bathroomsCount,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.bathroomsCount, home),
    },
    halfBathroomsCount: {
      key: getFieldKey(PropertyInfoFields.halfBathroomsCount),
      value: home.halfBathroomsCount,
      isVerified: false,
      history: getFieldHistory(PropertyInfoFields.halfBathroomsCount, home),
    },
    squareFootage: {
      key: getFieldKey(PropertyInfoFields.squareFootage),
      value: home.squareFootage?.value,
      isVerified: false,
      history: {
        lastVerifiedAt: home.squareFootage?.lastVerifiedAt,
        lastVerifiedBy: home.squareFootage?.lastVerifiedBy,
      },
    },
  }
}

export const propertyInfoCustomFieldsFilter = (customFields?: CustomFieldSectionProjection[]) =>
  orderBy(customFields, customField => customField.section.name, ['asc'])
    ?.filter(fields => !internalConfigurationSectionsIds.includes(fields?.section.id))
    ?.filter(fields => !homeownerInfoSectionsIds.includes(fields?.section.id))
    ?.filter(fields => !listingAndDistributionSectionsIds.includes(fields?.section.id))
    ?.filter(notReadOnlySection)
    ?.filter(withFields)
