export interface CircleProps {
  size?: string
  color?: string
}

export enum Alignment {
  start = 'flex-start',
  center = 'center',
  end = 'flex-end',
}

export interface LoadingProps extends CircleProps {
  absolute?: boolean
  alignment?: Alignment
  dataCy?: string
  className?: string
  minHeight?: string
}
