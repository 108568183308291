import arriereBackoffice, { BookingsArgs } from '@avantstay/arriere-clients/dist/arriereBackoffice'

// eslint-disable-next-line import/no-cycle
import {
  BookingPaymentAuditInput,
  BookingReasonForStayProps,
  BookingRiskVerificationProps,
  ExportBookingsListQueryProps,
} from '@new/domains/bookings/screens/bookingsList/BookingsList.types'

import { exportBookingsListCSV } from '@queries/exportCsv/exportBookingsListCSV'
import fetchApi from '@utils/fetchApi'

const checkTimeFragment = {
  originalTime: true,
  effectiveTime: true,
  custom: {
    time: true,
    status: true,
  },
}

interface BookingsListProps {
  filters: BookingsArgs
  shouldIncludePreStayIssuesData: boolean
  shouldIncludeUpcomingArrivalsData: boolean
  shouldIncludeRiskManagementQuickFilterSelected: boolean
}

export const bookingsList = {
  // Queries
  async getBookingsList({
    filters,
    shouldIncludePreStayIssuesData,
    shouldIncludeUpcomingArrivalsData,
    shouldIncludeRiskManagementQuickFilterSelected,
  }: BookingsListProps) {
    return arriereBackoffice.queries.bookings.raw({
      __alias: shouldIncludePreStayIssuesData
        ? 'getBookingsForPreStayIssues'
        : 'getBookingsListRaw',
      __args: filters,
      pages: true,
      total: true,
      results: {
        paymentAudit: {
          status: true,
          paymentNotes: true,
          auditNotes: true,
          followUpDate: true,
          paymentPlan: true,
          isRefund: true,
          paymentPlanDate: true,
          updatedBy: { name: true, kind: true },
        },
        acquisition: {
          isAcquisition: true,
        },
        address: { regionId: true },
        checkInInstructionsLastSent: shouldIncludePreStayIssuesData,
        checkInInstructionsStatus: shouldIncludePreStayIssuesData,
        checkInIssues: shouldIncludePreStayIssuesData,
        checkInNote: shouldIncludePreStayIssuesData
          ? {
              author: true,
              note: true,
            }
          : undefined,
        confirmationId: true,
        createdAt: true,
        couponCode: true,
        detailedCheckIn: checkTimeFragment,
        detailedCheckOut: checkTimeFragment,
        earlyCheckInStatus: shouldIncludePreStayIssuesData,
        emails: true,
        leadTime: true,
        riskVerification: {
          status: true,
          note: true,
          updatedBy: { kind: true, name: true },
        },
        folio: {
          summary: {
            balance: true,
            paid: { credits: true, total: true },
            total: true,
          },
        },
        guestName: true,
        guests: true,
        pets: true,
        id: true,
        hash: true,
        isRepeated: true,
        period: { start: true, end: true },
        home: {
          lifecycle: { riskLevel: true },
          id: true,
          name: true,
          region: { id: true, name: true },
          maxOccupancy: true,
          econPropertyFinancialHealth: true,
        },
        issues: true,
        phones: true,
        reservationType: {
          id: true,
          name: true,
        },
        securityDepositStatus: true,
        source: true,
        status: true,
        tags: {
          full: {
            tag: {
              hierarchy: true,
              name: true,
              vipLevel: true,
            },
          },
          short: true,
        },
        cancellationInfo: {
          createDate: true,
          author: {
            name: true,
          },
        },
        marketingInfo:
          shouldIncludeUpcomingArrivalsData || shouldIncludeRiskManagementQuickFilterSelected
            ? {
                purposeOfStay: true,
                notes: true,
                children: true,
                infants: true,
              }
            : undefined,
        cleaner: shouldIncludeUpcomingArrivalsData
          ? {
              id: true,
              name: true,
              status: true,
              dueDate: true,
            }
          : undefined,
        inspector: shouldIncludeUpcomingArrivalsData
          ? {
              id: true,
              name: true,
              status: true,
              dueDate: true,
            }
          : undefined,
        isBackToBack: shouldIncludeUpcomingArrivalsData,
        valueAddedServices: shouldIncludeUpcomingArrivalsData
          ? {
              name: true,
              data: {
                name: true,
                dateTime: true,
                quantity: true,
                description: true,
                vendorName: true,
                fridgePackages: {
                  name: true,
                },
                packages: {
                  quantity: true,
                },
              },
            }
          : undefined,
      },
    })
  },

  async getPossibleTagsList() {
    return arriereBackoffice.queries.bookingTags
      .memoRaw({
        __alias: 'getBookingTags',
        id: {
          tagLevel: true,
          id: true,
        },
        name: true,
        hierarchy: true,
      })
      .then(({ data }) => data)
  },

  async getPossibleRiskVerificationStatusList() {
    return arriereBackoffice.queries.bookingRiskVerificationStatus
      .memoRaw()
      .then(({ data }) => data)
  },

  async createBookingsListCSV({ exportBody, variables }: ExportBookingsListQueryProps) {
    return fetchApi(
      exportBookingsListCSV(exportBody),
      variables,
      undefined,
      `${process.env.REACT_APP_ARRIERE_BACKOFFICE_CSV}?page=pagination.page`,
    )
  },

  async updateBookingRiskVerification({ bookingId, status, note }: BookingRiskVerificationProps) {
    return arriereBackoffice.mutations.booking.raw({
      __alias: 'updateBookingRiskVerification',
      updateRiskVerification: {
        __args: {
          bookingId,
          status,
          note,
        },
      },
    })
  },

  async updateBookingReasonForStay({ bookingId, purposeOfStay }: BookingReasonForStayProps) {
    return arriereBackoffice.mutations.booking.raw({
      __alias: 'updateBookingReasonForStay',
      alterDetails: {
        __args: {
          bookingId,
          purposeOfStay,
        },
      },
    })
  },

  async updatePaymentAudit(args: BookingPaymentAuditInput) {
    return arriereBackoffice.mutations.paymentAudit.raw({
      __alias: 'updatePaymentAudit',
      __args: { bookingId: args.toUpdate.bookingId },
      save: {
        __args: {
          input: { ...args },
        },
      },
    })
  },

  async getUpcomingArrivalsBookingInfo(bookingId: string) {
    return arriereBackoffice.queries.booking.raw({
      __alias: 'getUpcomingArrivalBookingInfo',
      __args: { bookingId },
    })
  },

  async getFinancialHealthDescription() {
    return arriereBackoffice.queries.econPropertyFinancialHealthDescription({
      color: true,
      description: true,
    })
  },
}
