import { observer } from 'mobx-react'
import { useEffect } from 'react'

import { IconArrow } from '@avantstay/backoffice-vectors'

import * as S from './NavigationArrows.styles'

interface NavigationArrowsProps {
  blue?: boolean
  className?: string
  onNext: VoidFunction
  onPrev: VoidFunction
  hasNext: boolean
  hasPrev: boolean
}

const _NavigationArrows = ({
  blue,
  className,
  onNext,
  onPrev,
  hasPrev,
  hasNext,
}: NavigationArrowsProps) => {
  const handleKeyboard = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft' && hasPrev) {
      onPrev()
    }
    if (event.key === 'ArrowRight' && hasNext) {
      onNext()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyboard)

    return () => {
      document.removeEventListener('keyup', handleKeyboard)
    }
  }, [hasPrev, hasNext])

  return (
    <S.Root className={className}>
      <S.ArrowButton onClick={onPrev} disabled={!hasPrev} blue={blue}>
        <IconArrow style={{ transform: 'rotate(-90deg)' }} />
      </S.ArrowButton>

      <S.ArrowButton onClick={onNext} disabled={!hasNext} blue={blue}>
        <IconArrow style={{ transform: 'rotate(90deg)' }} />
      </S.ArrowButton>
    </S.Root>
  )
}

export const NavigationArrows = observer(_NavigationArrows)
