import React, { forwardRef, Ref, SVGProps } from 'react'

const _IconEdit2 = (props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} ref={ref}>
      <g clipPath="url(#clip0_302_28801)">
        <path
          d="M7.3335 2.66699H2.66683C2.31321 2.66699 1.97407 2.80747 1.72402 3.05752C1.47397 3.30756 1.3335 3.6467 1.3335 4.00033V13.3337C1.3335 13.6873 1.47397 14.0264 1.72402 14.2765C1.97407 14.5265 2.31321 14.667 2.66683 14.667H12.0002C12.3538 14.667 12.6929 14.5265 12.943 14.2765C13.193 14.0264 13.3335 13.6873 13.3335 13.3337V8.66699"
          stroke="currentColor"
          stroke-width="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.3335 1.66617C12.5987 1.40095 12.9584 1.25195 13.3335 1.25195C13.7086 1.25195 14.0683 1.40095 14.3335 1.66617C14.5987 1.93138 14.7477 2.29109 14.7477 2.66617C14.7477 3.04124 14.5987 3.40095 14.3335 3.66617L8.00016 9.9995L5.3335 10.6662L6.00016 7.9995L12.3335 1.66617Z"
          stroke="currentColor"
          stroke-width="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_302_28801">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconEdit2 = forwardRef(_IconEdit2)
