import { DocumentNode } from 'graphql'

import arriereBackoffice, {
  MergeAndAcquisitionIncluded,
  OnlineTravelAgency,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  HomeResults,
  MarketsAirbnbTripIssuesParams,
  PropertiesAirbnbTripIssuesParams,
  PropertiesDatesParams,
  PropertiesFinanceParams,
  PropertiesSyncStatusParams,
  SharedPropertiesParams,
} from '@new/domains/properties/screens/viewMode/ViewMode.types'

import { homeProfile } from '@arriere/modules/fragments/homeProfile'
import { npsRatingsOverviewFragment, ratingsOverviewFragment } from '@arriere/modules/regions'
import fetchApi from '@utils/fetchApi'

export default {
  // viewMode.configurationStatus
  async getHomesConfigurationStatus({
    pagination,
    regionIds,
    name,
    onlyActive,
    sort,
    ...params
  }: SharedPropertiesParams) {
    return arriereBackoffice.queries.homes({
      __alias: 'getHomesConfigurationStatus',
      __args: {
        pagination,
        regionIds,
        name,
        onlyActive,
        sort,
        ...params,
      },
      total: true,
      pages: true,
      results: {
        ...homeProfile,
        activeLeaseMonthlyAmount: true,
        petPolicy: true,
        revenueModel: true,
        configurationStatus: {
          accessInstructions: true,
          fallbackCode: true,
          galleryPicturesCount: true,
          lockType: true,
          manualSections: true,
          pricing: true,
          rooms: {
            total: true,
            configured: true,
          },
          strPermit: true,
        },
      },
    })
  },

  // viewMode.configurationStatusOTA
  async getHomesConfigurationStatusOTA({
    pagination,
    regionIds,
    name,
    onlyActive,
    onlyWithDownListings,
    sort,
    ...params
  }: SharedPropertiesParams) {
    const otaWithDownListings = onlyWithDownListings
      ? {
          otaWithDownListings: [],
        }
      : {}

    return arriereBackoffice.queries
      .distribution({
        __alias: 'getHomesConfigurationStatusOTA',
        homes: {
          __args: {
            pagination,
            regionIds,
            onlyActive,
            sort,
            name,
            ...otaWithDownListings,
            ...params,
          },
          total: true,
          pages: true,
          results: {
            ...homeProfile,
            distributions: {
              __args: {
                activeOnly: onlyActive || true,
              },
              active: true,
              agencyAccount: {
                agency: true,
                channel: true,
                market: true,
                notes: true,
              },
              status: true,
              statuses: {
                category: true,
                status: {
                  author: {
                    name: true,
                    kind: true,
                  },
                  startTime: true,
                  updatedAt: true,
                  endTime: true,
                  errors: {
                    code: true,
                    internalCategory: true,
                    reason: true,
                    ts: true,
                  },
                  warnings: {
                    code: true,
                    internalCategory: true,
                    reason: true,
                    ts: true,
                  },
                  status: true,
                },
              },
              lastUpdateAt: true,
            },
            links: {
              listings: {
                leadSource: true,
                url: true,
              },
              manual: true,
            },
          },
        },
      })
      .then(res => res.homes)
  },

  // viewMode.finance
  async getHomesFinance({
    pagination,
    from,
    to,
    granularity,
    fromPastYear,
    toPastYear,
    regionIds,
    name,
    onlyActive,
    sort,
    quantity,
    ...params
  }: PropertiesFinanceParams) {
    const regularFinance = arriereBackoffice.queries.homes({
      __alias: 'getHomesFinanceRegularFinance',
      __args: {
        regionIds,
        name,
        onlyActive,
        pagination,
        sort,
        ...params,
      },
      total: true,
      pages: true,
      results: {
        ...homeProfile,
        activeLeaseMonthlyAmount: true,
        leases: {
          startDate: true,
          endDate: true,
        },
        upcomingWeekendsRelativeOccupancy: { __args: { quantity } },
        indicators: {
          __args: { granularity },
          finance: {
            realizedRevenue: {
              __args: { from, to, mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all },
              aggregatedValue: true,
            },
          },
          sales: {
            occupancyRate: {
              __args: { from, to },
              aggregatedValue: true,
            },
            weekendOccupancyRate: {
              __args: { from, to },
              aggregatedValue: true,
            },
          },
        },
      },
    })

    // TODO: Remove this query call once we have API to download the Finance CSV
    const pastRealizedRevenue = arriereBackoffice.queries.homes({
      __alias: 'getHomesFinancePastRealizedRevenue',
      __args: {
        regionIds,
        name,
        onlyActive,
        pagination,
        sort,
        ...params,
      },
      total: true,
      pages: true,
      results: {
        indicators: {
          __args: { granularity },
          finance: {
            realizedRevenue: {
              __args: {
                from: fromPastYear,
                to: toPastYear,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all,
              },
              aggregatedValue: true,
            },
          },
        },
      },
    })

    return Promise.all([regularFinance, pastRealizedRevenue]).then(response => {
      const mainData = response[0]

      const combinedResults = mainData.results.flatMap((data, index) => {
        return {
          ...data,
          indicators: {
            ...data.indicators,
            finance: {
              ...data.indicators.finance,
              pastRealizedRevenue: response[1].results[index].indicators.finance.realizedRevenue,
            },
          },
        }
      })

      return {
        total: mainData.total,
        pages: mainData.pages,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        results: [...combinedResults] as unknown as HomeResults[],
      }
    })
  },

  // viewMode.occupancy
  async getHomesOccupancy({
    pagination,
    from,
    to,
    name,
    regionIds,
    onlyActive,
    sort,
    quantity,
    granularity,
    ...params
  }: PropertiesDatesParams) {
    return arriereBackoffice.queries.homes({
      __alias: 'getHomesOccupancy',
      __args: {
        regionIds,
        name,
        onlyActive,
        pagination,
        sort,
        ...params,
      },
      total: true,
      pages: true,
      results: {
        ...homeProfile,
        upcomingWeekendsRelativeOccupancy: { __args: { quantity } },
        upcomingWeekendsOccupancy: {
          __args: { quantity: 10 },
          occupied: true,
          date: true,
          inactive: true,
        },
        indicators: {
          __args: { granularity },
          finance: {
            bookingVolume: {
              __args: {
                from,
                to,
                includeBuyouts: false,
                mergeAndAcqIncluded: MergeAndAcquisitionIncluded.all,
              },
              aggregatedValue: true,
            },
          },
          sales: {
            occupancyRate: {
              __args: { from, to },
              aggregatedValue: true,
            },
            weekendOccupancyRate: {
              __args: { from, to },
              aggregatedValue: true,
            },
          },
        },
      },
    })
  },

  // viewMode.performance
  async getHomesPerformance({
    from,
    to,
    pagination,
    name,
    regionIds,
    onlyActive,
    sort,
    granularity,
    ...params
  }: PropertiesDatesParams) {
    return arriereBackoffice.queries.homes({
      __alias: 'getHomesPerformance',
      __args: {
        regionIds,
        name,
        onlyActive,
        pagination,
        sort,
        ...params,
      },
      total: true,
      pages: true,
      results: {
        ...homeProfile,
        indicators: {
          __args: { granularity },
          finance: {
            revpau: {
              __args: { from, to },
              period: true,
              weekdays: true,
              weekends: true,
            },
            adr: {
              __args: { from, to },
              period: true,
            },
          },
        },
      },
    })
  },

  // viewMode.predictedRevenue

  // viewMode.ratings
  async getHomesRatings({
    from,
    to,
    pagination,
    name,
    regionIds,
    onlyActive,
    sort,
    granularity,
    ...params
  }: PropertiesDatesParams) {
    return arriereBackoffice.queries.homes
      .raw({
        __alias: 'getHomesRatings',
        __args: {
          regionIds,
          name,
          onlyActive,
          pagination,
          sort,
          ...params,
        },
        total: true,
        pages: true,
        results: {
          ...homeProfile,
          ratings: {
            __args: {
              period: {
                start: from,
                end: to,
              },
            },
            homeAway: {
              ...ratingsOverviewFragment.homeAway,
              numberOfBookings: true,
            },
            airBnb: {
              ...ratingsOverviewFragment.airBnb,
              numberOfBookings: true,
            },
          },
          indicators: {
            __args: { granularity },
            customerExperience: {
              nps: {
                __args: { from, to },
              },
              npsInfo: {
                __args: { from, to },
                ...npsRatingsOverviewFragment,
              },
            },
          },
        },
      })
      .then(({ data }) => {
        return {
          ...data,
          results: data.results.map(result => ({
            ...result,
            marketName: result?.region?.name,
            marketId: result?.region?.id,
          })),
        }
      })
  },

  async getHomesSimpleList(name: string, from: string, to: string) {
    return arriereBackoffice.queries.homes
      .raw({
        __alias: 'getHomesSimpleList',
        __args: { name },
        results: {
          id: true,
          name: true,
          title: true,
          region: {
            name: true,
          },
          lifecycle: {
            guestReadinessStatus: true,
          },
          calendar: {
            __args: { from, to },
            date: true,
            status: true,
          },
          maxOccupancy: true,
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0]?.message)
        }
        return data.results
      })
  },
  async getPropertiesSimpleSearch(name: string) {
    return arriereBackoffice.queries.homes
      .raw({
        __alias: 'getPropertiesSimpleSearch',
        __args: { name },
        results: {
          id: true,
          name: true,
          active: true,
          title: true,
          details: {
            maintenanceStatus: true,
            onBoardingStatus: true,
            liveSince: true,
            liveUntil: true,
          },
          lifecycle: {
            riskLevel: true,
          },
          thumbnail: {
            url: true,
          },
          createdAt: true,
          region: {
            id: true,
            name: true,
          },
          isAncestor: true,
          isSuccessor: true,
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0]?.message)
        }
        return data.results
      })
  },
  async getPropertiesById(homeIds: string[]) {
    return arriereBackoffice.queries.homes
      .raw({
        __alias: 'getPropertiesById',
        __args: { homeIds },
        results: {
          id: true,
          name: true,
          active: true,
          title: true,
          details: {
            maintenanceStatus: true,
            onBoardingStatus: true,
            liveSince: true,
            liveUntil: true,
          },
          lifecycle: {
            riskLevel: true,
          },
          thumbnail: {
            url: true,
          },
          createdAt: true,
          region: {
            id: true,
            name: true,
          },
          isAncestor: true,
          isSuccessor: true,
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0]?.message)
        }
        return data.results
      })
  },
  async getHomesListNoRestrictions(name: string) {
    return arriereBackoffice.queries.homes
      .raw({
        __alias: 'getHomesListNoRestrictions',
        __args: { name },
        results: {
          id: true,
          name: true,
          title: true,
          region: {
            name: true,
          },
        },
      })
      .then(({ data, errors }) => {
        if (errors) {
          throw Error(errors[0]?.message)
        }
        return data.results
      })
  },
  async createPropertiesListCSV(
    variables:
      | PropertiesDatesParams
      | PropertiesFinanceParams
      | SharedPropertiesParams
      | PropertiesAirbnbTripIssuesParams,
    query: DocumentNode,
  ) {
    return fetchApi(
      query,
      variables,
      undefined,
      `${process.env.REACT_APP_ARRIERE_BACKOFFICE_CSV}?page=pagination.page`,
    )
  },
}

// viewMode.locations
export const getHomesLocations = async ({
  pagination,
  name,
  regionIds,
  onlyActive,
  sort,
  ...params
}: SharedPropertiesParams) => {
  return arriereBackoffice.queries.homes
    .raw({
      __alias: 'getHomesLocations',
      __args: {
        regionIds,
        name,
        onlyActive,
        pagination,
        sort,
        ...params,
      },
      total: true,
      pages: true,
      results: {
        ...homeProfile,
        revenueModel: true,
        latitude: true,
        longitude: true,
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors.length - 1]?.message)
      }

      return data
    })
}

// viewMode.airbnbStatus
export const getHomesOTAStatus = async ({
  pagination,
  regionIds,
  name,
  withOffboarded,
  sort,
  agency,
  currentOnly,
  onlyWithDownListings,
  issuesFilter,
  ...params
}: PropertiesSyncStatusParams) => {
  const otaWithDownListings =
    onlyWithDownListings && agency ? { otaWithDownListings: [agency] } : {}

  return arriereBackoffice.queries.distribution
    .raw({
      __alias: 'getHomesOTAStatus',
      homes: {
        __args: {
          pagination,
          regionIds,
          withOffboarded,
          sort,
          name,
          issuesFilter,
          ...otaWithDownListings,
          ...params,
        },
        total: true,
        pages: true,
        results: {
          ...homeProfile,
          distributions: {
            __args: {
              activeOnly: true,
              currentOnly,
              agency,
            },
            homeId: true,
            active: true,
            agencyAccountId: true,
            agencyAccount: {
              agency: true,
              channel: true,
              market: true,
              notes: true,
            },
            status: true,
            statuses: {
              category: true,
              status: {
                author: {
                  name: true,
                  kind: true,
                },
                startTime: true,
                updatedAt: true,
                endTime: true,
                errors: {
                  category: true,
                  code: true,
                  internalCategory: true,
                  reason: true,
                  ts: true,
                  resolution: {
                    __args: { agency },
                    priority: true,
                  },
                },
                warnings: {
                  category: true,
                  code: true,
                  internalCategory: true,
                  reason: true,
                  ts: true,
                  resolution: {
                    __args: { agency },
                    priority: true,
                  },
                },
                status: true,
              },
            },
            lastUpdateAt: true,
            externalHomeId: true,
            homeTitle: true,
          },
          listings: {
            __args: {
              agency,
            },
            active: true,
            distributionSettings: {
              airbnbSettings: {
                permitId: true,
              },
            },
            agencyAccount: {
              agency: true,
              channel: true,
              channelAccountId: true,
              id: true,
              market: true,
            },
            apiConnected: true,
            channels: {
              active: true,
              listingId: true,
              listingLink: true,
              ota: true,
            },
            externalHomeId: true,
            title: true,
          },
          links: {
            listings: {
              leadSource: true,
              url: true,
            },
            manual: true,
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors.length - 1]?.message)
      }

      return data.homes
    })
}

export const getAirbnbAccountsList = async () => {
  return await arriereBackoffice.queries.superHostDashboard({
    __alias: 'getAirbnbAccounts',
    airbnbAccounts: {
      id: true,
      accountName: true,
      isCurrentlySuperHost: true,
    },
  })
}

export const getHomesDataForAirbnbTripIssues = async (homeIds: string[]) => {
  return arriereBackoffice.queries.homes
    .raw({
      __alias: 'getHomesDataForAirbnbTripIssues',
      __args: { homeIds },
      results: {
        id: true,
        name: true,
        title: true,
        region: {
          name: true,
        },
        listings: {
          __args: { agency: OnlineTravelAgency.airbnb },
          agencyAccount: {
            market: true,
            channelAccountId: true,
          },
        },
        lifecycle: {
          listingStatus: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw Error(errors[0]?.message)
      }

      return data
    })
}

export const getHomesAirbnbTripIssues = async ({
  homeIds,
  regionIds,
  terms,
  listedOnly,
  qualityStandardState,
  orderBy,
  sortBy,
  pagination,
}: PropertiesAirbnbTripIssuesParams) => {
  return arriereBackoffice.queries.airbnbDistribution
    .raw({
      __alias: 'getHomesAirbnbTripIssues',
      tripIssuesSearch: {
        __args: {
          homeIds,
          regionIds,
          terms,
          listedOnly,
          qualityStandardState,
          orderBy,
          sortBy,
          pagination,
        },
        total: true,
        pages: true,
        results: {
          homeId: true,
          listingId: true,
          accountId: true,
          qualityStandardsState: true,
          qualityStandardsStateDate: true,
          caseStats: {
            open: true,
            closed: true,
          },
          stats: {
            daysSinceLastTripIssueCount: true,
            bookingsCompletedSinceLastIssueCount: true,
            bookingsCount: true,
            bookingsUpcomingCount: true,
            bookingsUpcomingAllChannelsCount: true,
            bookingIssuesCount: true,
            bookingIssuesRatio: true,
            lowReviewsCount: true,
            csViolationCount: true,
            tripIssuesCount: true,
            hostCancellationCount: true,
            hostCancellationDetails: {
              hostCancellationIssuesCount: true,
            },
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors) {
        throw Error(errors[0]?.message)
      }

      const homeIds = data.tripIssuesSearch.results.map(result => result.homeId)

      return getHomesDataForAirbnbTripIssues(homeIds)?.then(homesData => {
        return {
          total: data.tripIssuesSearch.total,
          pages: data.tripIssuesSearch.pages,
          homeIds: homeIds,
          response: data.tripIssuesSearch.results.map(result => {
            const homeData = homesData.results.find(home => home.id === result.homeId)
            return {
              ...homeData,
              regionName: homeData?.region?.name ?? undefined,
              tripIssuesStats: {
                ...result,
                accountId: result.accountId ?? undefined,
                listingId: result.listingId ?? undefined,
                qualityStandardsState: result.qualityStandardsState ?? undefined,
                qualityStandardsStateDate: result.qualityStandardsStateDate ?? undefined,
                caseStats: {
                  open: result.caseStats?.open ?? undefined,
                  closed: result.caseStats?.closed ?? undefined,
                },
                stats: {
                  ...result.stats,
                  daysSinceLastTripIssueCount:
                    result.stats?.daysSinceLastTripIssueCount ?? undefined,
                  bookingsCompletedSinceLastIssueCount:
                    result.stats?.bookingsCompletedSinceLastIssueCount ?? undefined,
                  tripIssuesCount: result.stats?.tripIssuesCount ?? undefined,
                  bookingsCount: result.stats?.bookingsCount ?? undefined,
                  bookingsUpcomingCount: result.stats?.bookingsUpcomingCount ?? undefined,
                  bookingsUpcomingAllChannelsCount:
                    result.stats?.bookingsUpcomingAllChannelsCount ?? undefined,
                  bookingIssuesCount: result.stats?.bookingIssuesCount ?? undefined,
                  bookingIssuesRatio: result.stats?.bookingIssuesRatio ?? undefined,
                  lowReviewsCount: result.stats?.lowReviewsCount ?? undefined,
                  csViolationCount: result.stats?.csViolationCount ?? undefined,
                  hostCancellationDetails: {
                    hostCancellationIssuesCount:
                      result.stats?.hostCancellationDetails?.hostCancellationIssuesCount ??
                      undefined,
                  },
                },
              },
            }
          }),
        }
      })
    })
}

export const getMarketsAirbnbTripIssues = async ({ pagination }: MarketsAirbnbTripIssuesParams) => {
  return arriereBackoffice.queries.regions
    .raw({
      __alias: 'getMarketsAirbnbTripIssues',
      __args: { pagination },
      total: true,
      pages: true,
      results: {
        id: true,
        name: true,
        homeCount: true,
        tripIssuesStats: {
          regionId: true,
          hostIds: true,
          stats: {
            homesCount: true,
            homesWithIssuesCount: true,
            tripIssuesCount: true,
            bookingsCount: true,
            bookingsUpcomingCount: true,
            bookingsUpcomingAllChannelsCount: true,
            bookingIssuesCount: true,
            bookingIssuesRatio: true,
            lowReviewsCount: true,
            csViolationCount: true,
            hostsCancellationDetails: {
              hostsCancellationIssuesCount: true,
            },
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors) {
        throw Error(errors[0]?.message)
      }

      // We are treating data here to get expected RowType in DataGrid
      // since some objects can return as null when data is not provided
      const response = data.results?.map(result => ({
        ...result,
        tripIssuesStats: {
          ...result.tripIssuesStats,
          stats: {
            ...result.tripIssuesStats?.stats,
            homesCount: result.tripIssuesStats?.stats?.homesCount ?? undefined,
            homesWithIssuesCount: result.tripIssuesStats?.stats?.homesWithIssuesCount ?? undefined,
            tripIssuesCount: result.tripIssuesStats?.stats?.tripIssuesCount ?? undefined,
            bookingsCount: result.tripIssuesStats?.stats?.bookingsCount ?? undefined,
            bookingsUpcomingCount:
              result.tripIssuesStats?.stats?.bookingsUpcomingCount ?? undefined,
            bookingsUpcomingAllChannelsCount:
              result.tripIssuesStats?.stats?.bookingsUpcomingAllChannelsCount ?? undefined,
            bookingIssuesCount: result.tripIssuesStats?.stats?.bookingIssuesCount ?? undefined,
            bookingIssuesRatio: result.tripIssuesStats?.stats?.bookingIssuesRatio ?? undefined,
            lowReviewsCount: result.tripIssuesStats?.stats?.lowReviewsCount ?? undefined,
            csViolationCount: result.tripIssuesStats?.stats?.csViolationCount ?? undefined,
            hostCancellationDetails: {
              hostCancellationIssuesCount:
                result.tripIssuesStats?.stats?.hostsCancellationDetails
                  ?.hostsCancellationIssuesCount ?? undefined,
            },
          },
        },
      }))

      return {
        pages: data.pages,
        total: data.total,
        response,
      }
    })
}

const reviewIssueFragments = {
  description: true,
  lowReviewsCategories: true,
  negativeReviewsCategories: true,
  negativeReviewsTags: true,
  problematicBookingsCount: true,
}

const issueFragments = {
  bookingHash: true,
  before: {
    cancellation: true,
    csViolations: {
      category: true,
      tag: true,
    },
    reviewIssues: {
      lowCategoryRatings: {
        category: true,
        rating: true,
      },
      lowOverallRating: true,
      negativeCategoryTags: {
        category: true,
        tag: true,
      },
      otaReviewId: true,
    },
  },
  issues: {
    cancellation: true,
    csViolations: {
      category: true,
      tag: true,
    },
    reviewIssues: {
      lowCategoryRatings: {
        category: true,
        rating: true,
      },
      lowOverallRating: true,
      negativeCategoryTags: {
        category: true,
        tag: true,
      },
      otaReviewId: true,
    },
  },
}

export const getHomeAirbnbTripIssueHistory = async (homeId: string) => {
  return arriereBackoffice.queries.distribution
    .raw({
      __alias: 'getHomeAirbnbTripIssueHistory',
      otaListingEvents: {
        __args: {
          agency: OnlineTravelAgency.airbnb,
          homeId,
        },
        results: {
          author: {
            name: true,
          },
          eventTime: true,
          kind: true,
          otaListingId: true,
          bookings: {
            hash: true,
            period: {
              end: true,
              start: true,
            },
          },
          upcomingBookings: {
            hash: true,
            period: {
              end: true,
              start: true,
            },
          },
          qualityStandardsNotified: {
            ...reviewIssueFragments,
            standard: true,
            standardCategory: true,
            issues: issueFragments,
          },
          reactivated: {
            ...reviewIssueFragments,
            message: true,
            issues: issueFragments,
          },
          suspended: {
            ...reviewIssueFragments,
            message: true,
            reactivationDate: true,
            restrictedCapabilities: true,
            standard: true,
            suspensionDate: true,
            upcomingBookingsHashes: true,
            issues: issueFragments,
          },
          qualityStandardsChanged: {
            newQualityStandards: true,
            qualityStandards: true,
          },
          reservationIssue: issueFragments,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors) {
        throw Error(errors[0]?.message)
      }

      return data.otaListingEvents.results
    })
}

export const unitsSearchFragment = {
  id: true,
  name: true,
  title: true,
  isAncestor: true,
  isSuccessor: true,
  region: {
    name: true,
  },
  rooms: {
    kind: true,
    sleepingPlaces: {
      amenity: {
        name: true,
        id: true,
      },
      roomId: true,
      quantity: true,
    },
  },
}

export const getUnitsSimpleSearch = async (query: string) => {
  return arriereBackoffice.queries.homes
    .raw({
      __alias: 'getUnitsSimpleSearch',
      __args: { name: query },
      results: {
        ...unitsSearchFragment,
      },
    })
    .then(({ data, errors }) => {
      if (errors) {
        throw Error(errors[0]?.message)
      }
      return data.results
    })
}

export const getAllMarketProperties = async (regionIds: string[]) => {
  return arriereBackoffice.queries.homes({
    __alias: 'getAllMarketProperties',
    __args: {
      regionIds,
      onlyActive: true,
    },
    results: {
      id: true,
      name: true,
    },
  })
}
